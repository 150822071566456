<template>
    <v-textarea
        :value="value"
        class="base-text-area"
        outlined
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
    export default {
        name: 'BaseTextArea',
        inheritAttrs: false,
        props: {
            value: String
        }
    }
</script>

<style lang="scss">
.v-textarea.base-text-area {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #273155;
    background: unset;
    margin-bottom: 16px !important;
    .v-counter {
        position: absolute;
        top: 22px;
        right: 20px;
        position: absolute;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 140%;
        letter-spacing: -0.02em;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    .v-text-field__slot {
        padding: 0;
    }
    .v-input__slot {
        border: 1px solid #EBEBEB;
        border-radius: 10px;
        box-shadow: rgba(30, 28, 46, 0.03);
        background: #fff !important;
        margin-bottom: 0;
        overflow: hidden;
    }
    &.v-text-field--outlined.v-input--is-focused fieldset,
    &.v-text-field--outlined.v-input--has-state fieldset {
        border: 1px solid #000;
    }
    &.v-text-field--outlined.error--text.v-input--is-focused fieldset,
    &.v-text-field--outlined.error--text.v-input--has-state fieldset {
        border: 2px solid #ff5252 ;
    }
    &.v-text-field--outlined.v-input--is-focused .v-counter,
    &.v-text-field--outlined.v-input--is-dirty .v-counter {
        top: 12px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    textarea {
        margin: 26px 0 10px 8px !important;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155 !important;
        height: 115px;
        resize: none;
        overflow: auto;
    }
    &.v-text-field--outlined fieldset {
        border: none;
    }
    .v-text-field__details {
        min-height: 0;
        padding: 0 !important;
    }
    &.v-text-field.v-text-field--enclosed .v-text-field__details {
        margin-bottom: 0;
    }
    .v-messages.theme--light {
        min-height: 0;
    }
    .v-label.theme--light {
        margin-left: 8px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        &.v-label--active {
            top: 35px !important;
        }
        @media all and (min-width: 768px) {
            display: none;
        }
    }
    .v-messages.theme--light.error--text {
        margin-top: 4px;
    }
    fieldset {
        display:none
    }
    &.form {
        .v-input__slot {
            border: 1px solid #A9AEB2;
        }
        textarea {
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
        }
    }
}
</style>
