<template>
    <div class="trip-preview-card" v-if="translates && translates[langUrl]">
        <div class="trip-preview-card__carousel">
            <div :style="`background-image: url(${ imageSrc(trip.pictures[trip.mainPictureIndex]) })` " class="trip-preview-card__img"></div>
            <div class="trip-preview-card__carousel__label" v-if="trip.level">
                <img src="../../../assets/lock.svg" />
                {{ TRIP_LEVEL_TITLE[trip.level] }}
            </div>
        </div>
        <div class="trip-preview-card__info">
            <h3 class="trip-preview-card__title">{{ trip.name }}</h3>
            <template v-if="nearestArrival">
                <div class="trip-preview-card__details-container">
                    <div class="trip-preview-card__details-container__details">
                        <span class="trip-preview-card__details-container__price" v-html="priceFormated(nearestArrival.price, nearestArrival.currency)"></span> / {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                    </div>
                </div>
            </template>
        </div>
        <v-spacer v-if="bloggerTrips" />
        <div class="trip-preview-card__description">
            <template v-if="trip.direction && trip.direction.length">
                <div class="trip-preview-card__description__container trip-preview-card__description__route">{{ translates[langUrl].startPlace[lang] }} 
                    <div>{{ trip.direction[0].country? trip.direction[0].country.title: '' }}{{ trip.direction[0].city? ', ' + trip.direction[0].city.title: '' }}</div>
                </div>
                <div class="trip-preview-card__description__container trip-preview-card__description__route">{{ translates[langUrl].finishPlace[lang] }}
                    <div> {{ trip.direction[trip.direction.length - 1].country? trip.direction[trip.direction.length - 1].country.title: '' }}{{ trip.direction[trip.direction.length - 1].city? ', ' + trip.direction[trip.direction.length - 1].city.title: '' }} </div>
                </div>
            </template>
            <div class="trip-preview-card__description__container trip-preview-card__description__types">
                {{ translates[langUrl].duration[lang] }} 
                <div>
                    {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                </div>
            </div>
            <div v-if="(trip.organizer || trip.user) && !bloggersList && !bloggerTrips" class="trip-preview-card__description__container trip-preview-card__description__organizer"> 
                Организатор: 
                <div v-if="trip.organizer && trip.organizer.firstname">{{ trip.organizer.firstname }} {{ trip.organizer.lastname }}</div>
                <div v-else>{{ trip.user.firstname }} {{ trip.user.lastname }}</div>
            </div>
        </div>
        <v-spacer v-if="!bloggerTrips" />
        <div class="trip-preview-card__buttons">
            <BaseButton
                class="button-fill"
                style="width: 100%"
                @click="open( trip.code )">{{ translates[langUrl].button_openTrip[lang] }}</BaseButton>
        </div>
        <a
            ref="dowloadProgram"
            :href="downloadFile(trip.PDF)"
            target="_blank">
        </a>
        <v-dialog
            v-model="popupDownload"
            max-width="600px"
        >
            <PopupDownload
                @close="closePopupDownload"
                @download="download"
            />
        </v-dialog>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex';

    import store from '@/store';
    import { imageSrc, priceFormated } from '@/helpers'; 
    import { TRIP_STATUS, TRIP_STATUS_TITLE, TRIP_LEVEL_TITLE } from '@/vars';
    import PopupDownload from '../PopupDownload.vue'
    import BaseButton from '../../common/BaseButton.vue'

    export default {
        name: 'TripPreviewCard',
        components: {
            PopupDownload,
            BaseButton
        },
        props: {
            trip: {
                type: Object,
                default: () => {},
            },
            buttons: {
                type: Array,
                default: () => []
            },
            bloggersList: {
                type: Boolean,
                default: false
            },
            bloggerTrips: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            imageSrc,
            priceFormated,
            TRIP_STATUS,
            TRIP_STATUS_TITLE,
            TRIP_LEVEL_TITLE,
            popupDownload: false,
            langUrl: 'RoutePreviewCard'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('dictionaries', ['types', 'labels']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            nearestArrival() {
                if (this.trip.bloggerTrips && this.trip.bloggerTrips[0] && this.trip.bloggerTrips[0].start && this.trip.bloggerTrips[0].finish) {
                    return {
                        price: +this.trip.bloggerTrips[0].price + this.trip.bloggerTrips[0].bloggerAward,
                        currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                        dates: `${ moment(this.trip.bloggerTrips[0].start).format('DD MMM') } - ${ moment(this.trip.bloggerTrips[0].finish).format('DD MMM') }`
                    };
                }
                if (this.trip.arrivals[0] && this.trip.arrivals[0].start && this.trip.arrivals[0].finish) {
                    return {
                        price: +this.trip.arrivals[0].price,
                        currency: this.trip.arrivals[0].currency,
                        dates: `${ moment(this.trip.arrivals[0].start).format('DD MMM') } - ${ moment(this.trip.arrivals[0].finish).format('DD MMM') }`
                    };
                } 
                return null;
            },
            formattedMainType() {
                return this.types.filter(item => (item.code === this.trip.mainType))[0].title;
            },
            formattedLabels() {
                return this.labels.filter(item => (item.code === this.trip.label))[0].title;
            },
            formattedAdditionalTypes() {
                return this.types.filter(item => (this.trip.additionalTypes.indexOf(item.code) >= 0)).map(item => item.title);
            },
            status () {
                return TRIP_STATUS_TITLE[this.trip.status];
            },
            price () {
                let price = this.trip.arrivals[0] ? this.trip.arrivals[0].price : 0;
                return price = new Intl.NumberFormat('ru-RU').format(price);
            },
            mainPlacesArray () {
                return this.trip && this.trip.mainPlaces && this.trip.mainPlaces[0] ? this.trip.mainPlaces[0].split(',') : []
            },
            inFavorites() {
                if (this.user._id) {
                    return this.user.favoriteTrips.indexOf(this.trip._id) >= 0;
                }
                return false;
            },
        },

        methods: {
            async open(code) {
                if(this.bloggersList) {
                    await this.$router.push({ name: 'trip-bloggers', params: { code } });
                } else {
                    await store.commit('trips/CLEAR_ENTITY');
                    await this.$router.push({ name: 'blogger-route', params: { code } });
                }
            },
            async edit(id) {
                if(this.user._id) {
                    await this.$router.push({ name: 'trip-edit', params: { id } });
                } else {
                    this.authorization();
                    this.$root.$once('authorized', async () => {
                        await this.$router.push({ name: 'trip-edit', params: { id } });
                    });
                }
            },
            duration(arr) {
                let duration = arr.reduce((duration, currentValue) => {
                    return duration + currentValue.duration
                }, 0)
                return duration
            },
            lastDigitToWord (digit) {
                const lastFigure = parseInt(digit % 10);
                if(this.lang === 'ru') {
                    if (digit >= 11 && digit < 15) {
                        return 'дней';
                    }
                    else {
                        if (lastFigure == 1) return 'день';
                        if (lastFigure > 1 && lastFigure < 5) return 'дня';
                        if (lastFigure == 0 || lastFigure >= 5) return 'дней';
                    }
                } else {
                    if (lastFigure == 1) return 'day';
                    if (lastFigure > 1) return 'days';
                }
                return lastFigure
            },
            arrivalsDates() {
                if (this.trip.arrivals[0]) {
                    return `${ moment(this.trip.arrivals[0].start).format('DD MMM') } - ${ moment(this.trip.arrivals[0].finish).format('DD MMM') }`;
                } 
                return '';
            },
            async favoriteAdd(id) {
                await this.sendFeedback(`Пользователь ${ this.user.phone } добавил в избранное тур "${ this.trip.name }", ссылка: ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                await this.$emit('favoriteAdd', { id });
            },
            async favoriteDelete(id) {
                await this.$emit('favoriteDelete', { id });
            },
            async setStatus(id, status) {
                await this.$emit('setStatus', { id, status });
            },
            async download() {
                this.$refs.dowloadProgram.click();
            },
            async downloadProgram() {
                if(this.user._id) {
                    this.sendFeedback(`Пользователь ${ this.user.phone } скачал презентацию тура "${ this.trip.name }", ссылка: ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                    this.openPopupDownload()
                } else {
                    this.authorization();
                    this.$root.$once('authorized', async () => {
                        this.sendFeedback(`Пользователь ${ this.user.phone } скачал презентацию тура "${ this.trip.name }", ссылка: ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                        this.openPopupDownload()
                    });
                }
            },
            downloadFile(file) {
                return file ? `${ process.env.VUE_APP_STATIC_URL }/${ file.replace(/\\/g, '/') }` : ''
            },
            async download() {
                this.$refs.dowloadProgram.click();
            },
            openPopupDownload() {
                this.popupDownload = true;
            },
            closePopupDownload() {
                this.popupDownload = false;
            },
            async sendFeedback(comment) {
                this.feedback = {
                    trip: this.trip._id,
                    name: this.user.firstname,
                    phone: this.user.phone,
                    email: this.user.email,
                    comment: comment,
                    link: document.location.href
                };
                await store.commit('feedbacks/SET_ENTITY', this.feedback);
                await store.dispatch('feedbacks/save');
                await store.commit('feedbacks/CLEAR_ENTITY');
            },
            authorization() {
                if(!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                }
            },
        }
    }


</script>

<style lang="scss">
.trip-preview-card {
	display: flex;
	flex-direction: column;
	width: calc(100% - 40px);
	background: #FFFFFF;
	border-radius: 20px;
	margin-bottom: 20px;
	overflow: hidden;
    box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
    @media all and (max-width: 1400px) {
        margin: 0 auto 20px;
    }
    &__carousel {
        position: relative;
        width: 100%;
        height: 284px;

        &__button {
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6.5px 8px;
            background: #FFFFFF;
            border-radius: 158px;
            cursor: pointer;

            &__icon {
                width: 16px;
                height: 16px;
                // margin-right: 4px;
                // background-color: #FFFFFF;
            }

            &__text {
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #E61E4D;
                flex: none;
                order: 1;
                flex-grow: 0;
                margin: 0px 4px;
                &.button-fill {
                        color: #FFFFFF;
                    }
                }
                &.button-fill {
                    background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
                }
        }

        &__label {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 10px;
            position: absolute;
            left: 20px;
            top: 269px;
            background: #FFCD1D;
            border-radius: 5px;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.015em;
            color: #FFFFFF;

            &__second {
                left: 120px;
                top: 269px;
                background: #48A7FF;
            }
        }
        &__label {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 20px;
            top: 269px;
            width: 116px;
            height: 24px;
            background: #273155;
            border-radius: 5px;
            padding: 5px 10px;
            margin-bottom: 12px;
            border-radius: 5px;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.015em;
            color: #FFFFFF;
            img {
                height: 13px;
                width: 13px;
                margin-right: 4px;
            }
        }
        &__level {
            position: absolute;
            left: 20px;
            bottom: -12px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 6px 10px;
            background: #273155;
            border-radius: 5px;
            font-size: 12px;
            line-height: 12px;
            /* identical to box height, or 17px */
            letter-spacing: -0.015em;
            color: #FFFFFF;
            
            > img {
                margin-right: 5px;
            }
        }
    }

    &__img {
        height: 100%;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0px;

    }

    &__title{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        margin-bottom: 4px;
    }

    &__stars {
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */
        // text-align: right;
        letter-spacing: -0.02em;
        color: #707378;
    }

    &__comment{
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #484848;
        opacity: 0.5;
        margin-bottom: 16px;
    }

    &__details-container {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        justify-content: space-between;
        &__details {
            // min-width: 50%;
            // display: flex;
            // justify-content: flex-start;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #484848;
        }

        &__price {
            font-weight: 600;
            // min-width: 50%;
            // display: flex;
            // justify-content: flex-start;
        }

        &__dates {
            display: flex;
            flex-direction: row;
            // align-items: center;
            // min-width: 50%;
            // justify-content: flex-end;
            // flex-wrap: nowrap;
            &__icon {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }

            &__text {
                font-size: 14px;
                line-height: 140%;
                text-align: right;
                letter-spacing: -0.02em;
                color: #707378;
                white-space: nowrap;
            }
        }
    }

    &__description {
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            padding: 20px 20px 15px;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        &__span {
            color: #DA0A63;
        }
        
    }

    &__button-grey {
        padding: 10px 20px 10px 10px;
        margin-bottom: 18px;
        width: calc(100% - 48px);
        height: 44px;
        background: #F6F7F9;;;
        border-radius: 10px;
        align-self: center;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        letter-spacing: -0.02em;
        color: #484848;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        margin: 0 20px 10px;
        justify-content: space-between;
    }

    &__desktop {
        max-width: 335px;
        // height: 98%;
       .trip-preview-card__button {
           width: 100%;
       }
       .trip-preview-card__description {
           display: none;
       }
       .trip-preview-card__button {
           margin-top: 24px;
       }
    }
    &__news {
        max-width: 335px;
       .trip-preview-card__button {
           width: 100%;
       }
    }
}
</style>