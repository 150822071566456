<template>
    <div class="desktop-trip-preview-card" v-if="translates && translates[langUrl]">
        <div class="desktop-trip-preview-card__carousel">
            <div :style="`background-image: url(${ imageSrc(trip.pictures[trip.mainPictureIndex]) })` " class="desktop-trip-preview-card__img" @click="open( trip.code )"></div>
        </div>
        <div class="desktop-trip-preview-card__info-container">
            <div class="desktop-trip-preview-card__info">
                <div class="desktop-trip-preview-card__title-container">
                    <div class="desktop-trip-preview-card__label" v-if="trip.level">
                        <img src="../../../assets/lock.svg" />
                        <span>{{ TRIP_LEVEL_TITLE[trip.level] }}</span>
                    </div>
                    <h3 class="desktop-trip-preview-card__title">{{ trip.name }}</h3>
                </diV>
                <template v-if="nearestArrival">
                    <div v-if="!bloggersList" class="desktop-trip-preview-card__details-container">
                        <div class="desktop-trip-preview-card__details-container__details">
                            <span class="desktop-trip-preview-card__details-container__price"><span v-html="priceFormated(nearestArrival.price, nearestArrival.currency)"></span></span> / {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                        </div>
                    </div>
                </template>
            </div>
            <div class="desktop-trip-preview-card__description-and-buttons">
                <div class="desktop-trip-preview-card__description">
                    <div class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">
                            {{ translates[langUrl].startPlace[lang] }}
                        </div>
                        <div v-if="trip.direction[0]" class="desktop-trip-preview-card__description__value"> 
                            {{ trip.direction[0].country? trip.direction[0].country.title: '' }}
                            {{ trip.direction[0].state? ', ' + trip.direction[0].state.title: '' }}
                            {{ trip.direction[0].city? ', ' + trip.direction[0].city.title: '' }}
                        </div>
                    </div>
                    <div class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">
                            {{ translates[langUrl].finishPlace[lang] }}
                        </div>
                        <div v-if="trip.direction[0]" class="desktop-trip-preview-card__description__value"> 
                            {{ trip.direction[trip.direction.length - 1].country? trip.direction[trip.direction.length - 1].country.title: '' }}
                            {{ trip.direction[trip.direction.length - 1].state? ', ' + trip.direction[trip.direction.length - 1].state.title: '' }}
                            {{ trip.direction[trip.direction.length - 1].city? ', ' + trip.direction[trip.direction.length - 1].city.title: '' }}
                        </div>
                    </div>
                    <div class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">
                            {{ translates[langUrl].duration[lang] }}
                        </div>
                        <div class="desktop-trip-preview-card__description__value">
                            {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                        </div>
                    </div>
                </div>
                <div class="desktop-trip-preview-card__buttons">
                    <!-- <div 
                        v-if="buttons.indexOf('bloggerRequest') >= 0"
                        class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill" 
                        @click="tripRequest">{{ translates[langUrl].button_tripRequest[lang] }}</div> -->
                    <div 
                        class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill" 
                        @click="open( trip.code )">{{ translates[langUrl].button_openTrip[lang] }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from 'moment'
    import { mapState } from 'vuex';
    import { TRIP_STATUS, TRIP_STATUS_TITLE, TRIP_LEVEL_TITLE } from '@/vars';
    import PopupDownload from '../PopupDownload.vue'

    import { imageSrc, priceFormated } from '@/helpers';

    export default {
        name: 'TripPreviewCard',
        components: {
            PopupDownload
        },
        props: {
            trip: {
                type: Object,
                default: () => {},
            },
            buttons: {
                type: Array,
                default: () => []
            },
            bloggersList: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            imageSrc,
            priceFormated,
            TRIP_STATUS,
            TRIP_STATUS_TITLE,
            TRIP_LEVEL_TITLE,
            popupDownload: false,
            langUrl: 'RoutePreviewCard'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('dictionaries', ['types', 'labels']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            nearestArrival() {
                if (this.trip.bloggerTrips && this.trip.bloggerTrips[0] && this.trip.bloggerTrips[0].start && this.trip.bloggerTrips[0].finish) {
                    return {
                        price: +this.trip.bloggerTrips[0].price + this.trip.bloggerTrips[0].bloggerAward,
                        currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                        dates: `${ moment(this.trip.bloggerTrips[0].start).format('DD MMM') } - ${ moment(this.trip.bloggerTrips[0].finish).format('DD MMM') }`
                    };
                }
                if (this.trip.arrivals[0] && this.trip.arrivals[0].start && this.trip.arrivals[0].finish) {
                    return {
                        price: +this.trip.arrivals[0].price,
                        currency: this.trip.arrivals[0].currency,
                        dates: `${ moment(this.trip.arrivals[0].start).format('DD MMM') } - ${ moment(this.trip.arrivals[0].finish).format('DD MMM') }`
                    };
                } 
                return null;
            },
            formattedMainType() {
                if(this.trip.mainType) {
                    return this.types.filter(item => (item.code === this.trip.mainType))[0].title;
                } else {
                    return ''
                }
            },
            formattedAdditionalTypes() {
                if(this.trip.additionalTypes) {
                    return this.types.filter(item => (this.trip.additionalTypes.indexOf(item.code) >= 0)).map(item => item.title);
                } else {
                    return ''
                }
            },
            formattedLabels() {
                return this.labels.filter(item => (item.code === this.trip.label))[0].title;
            },
            status () {
                return TRIP_STATUS_TITLE[this.trip.status];
            },
            price () {
                let price = this.trip.arrivals[0] ? this.trip.arrivals[0].price : 0;
                return new Intl.NumberFormat('ru-RU').format(price);
            },
            mainPlacesArray () {
                return this.trip && this.trip.mainPlaces && this.trip.mainPlaces[0] ? this.trip.mainPlaces[0].split(',') : []
            },
            inFavorites() {
                if (this.user._id) {
                    return this.user.favoriteTrips.indexOf(this.trip._id) >= 0;
                }
                return false;
            },
            testLink() {
                return `${ process.env.VUE_APP_CURRENT_URL }/site/${ this.trip.code }`;
            },
            subdomain() {
                return `${ process.env.VUE_APP_PROTOCOL }${ this.trip.code }${ process.env.VUE_APP_SUBDOMAIN_POSTFIX }`;
            }
        },
        methods: {
            async open(code) {
                if(this.bloggersList) {
                    await this.$router.push({ name: 'trip-bloggers', params: { code } });    
                } else {
                    await this.$router.push({ name: 'blogger-route', params: { code } });
                }
            },
            async edit(id) {
                if(this.user._id) {
                    await this.$router.push({ name: 'trip-edit', params: { id } });
                } else {
                    this.authorization();
                    this.$root.$once('authorized', async () => {
                        await this.$router.push({ name: 'trip-edit', params: { id } });
                    });
                }
            },
            async tripRequest() {
                await this.$router.push({ name: 'trips-request-trip', params: { trip: this.trip._id } });
            },
            async download() {
                this.$refs.dowloadProgram.click();
            },
            downloadFile(file) {
                return file ? `${ process.env.VUE_APP_STATIC_URL }/${ file.replace(/\\/g, '/') }` : ''
            },
            duration(arr) {
                let duration = arr.reduce((duration, currentValue) => {
                    return duration + currentValue.duration
                }, 0)
                return duration
            },
            lastDigitToWord (digit) {
                const lastFigure = parseInt(digit % 10);
                if(this.lang === 'ru') {
                    if (digit >= 11 && digit < 15) {
                        return 'дней';
                    }
                    else {
                        if (lastFigure == 1) return 'день';
                        if (lastFigure > 1 && lastFigure < 5) return 'дня';
                        if (lastFigure == 0 || lastFigure >= 5) return 'дней';
                    }
                } else {
                    if (lastFigure == 1) return 'day';
                    if (lastFigure > 1) return 'days';
                }
                return lastFigure
            },
            arrivalsDates() {
                if (this.trip.arrivals[0]) {
                    return `${ moment(this.trip.arrivals[0].start).format('DD MMM') } - ${ moment(this.trip.arrivals[0].finish).format('DD MMM') }`;
                } 
                return '';
            },
            async favoriteAdd(id) {
                await this.$emit('favoriteAdd', { id });
                await this.sendFeedback(`Пользователь ${ this.user.phone } добавил в избранное тур "${ this.trip.name }", ссылка: ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
            },
            async favoriteDelete(id) {
                await this.$emit('favoriteDelete', { id });
            },
            async setStatus(id, status) {
                await this.$emit('setStatus', { id, status });
            },
            async downloadProgram() {
                if(this.user._id) {
                    this.sendFeedback(`Пользователь ${ this.user.phone } скачал презентацию тура "${ this.trip.name }", ссылка: ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                    this.openPopupDownload()
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.sendFeedback(`Пользователь ${ this.user.phone } скачал презентацию тура "${ this.trip.name }", ссылка: ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                        this.openPopupDownload()
                    });
                }
            },
            downloadFile(file) {
                return file ? `${ process.env.VUE_APP_STATIC_URL }/${ file.replace(/\\/g, '/') }` : ''
            },
            async download() {
                this.$refs.dowloadProgram.click();
            },
            openPopupDownload() {
                this.popupDownload = true;
            },
            closePopupDownload() {
                this.popupDownload = false;
            },
            async sendFeedback(comment) {
                this.feedback = {
                    trip: this.trip._id,
                    name: this.user.firstname,
                    phone: this.user.phone,
                    email: this.user.email,
                    comment: comment,
                    link: document.location.href
                };
                await store.commit('feedbacks/SET_ENTITY', this.feedback);
                await store.dispatch('feedbacks/save');
                await store.commit('feedbacks/CLEAR_ENTITY');
            },
            authorization() {
                if(!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                }
            },
        }
    }
</script>

<style lang="scss">
.desktop-trip-preview-card {
	display: flex;
	flex-direction: row;
	width: 100%;
    min-height: 280px;
	background: #FFFFFF;
	border-radius: 20px;
	margin-bottom: 20px;
	overflow: hidden;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.02), 0px 4px 16px rgba(44, 47, 56, 0.12);
    // @media all and (min-width: 1280px) {
    //     margin: auto;
    //     max-width: 1400px;
    // }
    &__carousel {
        position: relative;
        width: 335px;
        min-height: 100%;
        &__button {
            position: absolute;
            left: 16px;
            bottom: 16px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6.5px 8px;
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
            border-radius: 158px;
            cursor: pointer;
            &__icon {
                width: 16px;
                height: 16px;
            }

            &__text {
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #FFFFFF;
                flex: none;
                order: 1;
                flex-grow: 0;
                margin: 0px 4px;
            }
        }
    }

    &__place {
        position: absolute;
        display: flex;
        top: 20px;
        left: 24px
    }

    &__img {
        height: 100%;
        width: 335px;
        background-size: cover;
        cursor: pointer;
    }

    &__info-container {
        flex-direction: column;
        width: 100%;
    }

    &__info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 0;
        margin: 0 20px;
        border-bottom: 1px solid #F6F7F9;
    }

    &__label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 116px;
        background: #273155;
        padding: 5px 10px;
        margin-bottom: 12px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        img {
            height: 13px;
            width: 13px;
            margin-right: 4px;
        }
    }

    &__title{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        // margin-bottom: 4px;
    }

    &__stars {
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */
        // text-align: right;
        letter-spacing: -0.02em;
        color: #707378;
    }

    &__comment{
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #484848;
        opacity: 0.5;
        margin-bottom: 16px;
    }

    &__details-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 5px;
            @media all and (min-width: 768px) {
                flex-direction: column;
            }

        &__details {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #484848;
        }

        &__price {
            font-weight: 600;
        }

        &__dates {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 8px;
            @media all and (min-width: 768px) {
                justify-content: flex-end;
            }

            &__icon {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }

            &__text {
                font-size: 14px;
                line-height: 140%;
                text-align: right;
                letter-spacing: -0.02em;
                color: #707378;
            }
        }
    }
    &__description-and-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__description {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            padding: 24px 20px;
        &__line {
            margin-right: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 12px;
            }
        &__name {
            min-width: 140px;
            margin-right: 20px;
            @media all and (min-width: 1280px) {
                width: 140px;
            }
        }
        &__value span {
            white-space: nowrap;
            // display: flex;
            // justify-content: flex-start;
        }
        &__party {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 28px;
            width: 125px;
            &__participant {
                margin-right: -8px;
            }
        }

        &__span {
            color: #DA0A63;
            margin-left: 4px;
        }

        &__pdf {
            display: flex;
            color: #DA0A63;
            align-items: center;
            // margin-top: 15px;
            cursor: pointer;
        }
    }

    &__button-grey {
        padding: 10px 20px 10px 10px;
        margin-bottom: 18px;
        width: calc(100% - 48px);
        height: 32px;
        background: #F6F7F9;;;
        border-radius: 10px;
        align-self: center;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        letter-spacing: -0.02em;
        color: #484848;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 35px 20px 20px 0;
    }

    &__button {
        width: 195px;
        height: 48px;
        border: 1px solid #DA0A63 !important;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        line-height: 140%;
        padding: 10px;
        margin-bottom: 16px;
        letter-spacing: -0.02em;
        background: #FFFFFF;
        color: #DA0A63;
        cursor: pointer;
        &__icon {
            width: 15px;
            height: 15px;
            margin-left: 5px;
        }
    }

    &__button-fill {
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        color: #FFFFFF;
    }
}
</style>